import React from "react";
import s from "./button.module.css";

type Props = {
  size?: "big" | "small";
  children?: string;
  disabled?: boolean;
  textAlign?: "left" | "center";
  onClick?: () => void;
};

export const Button = ({
  onClick,
  children,
  disabled,
  textAlign = "left",
  size = "big",
}: Props) => {
  return (
    <button
      className={s.root}
      onClick={onClick}
      type="button"
      data-size={size}
      data-align={textAlign}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
