export const VkLogo = () => {
  return (
    <svg
      width="52"
      height="31"
      viewBox="0 0 52 31"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M28.3223 31C10.5559 31 0.422236 19.3634 0 0H8.89944C9.19176 14.2122 15.7527 20.2322 20.9494 21.4735V0H29.3292V12.2573C34.461 11.7297 39.8526 6.14414 41.6715 0H50.0512C49.3658 3.18653 47.9996 6.20366 46.038 8.86268C44.0764 11.5217 41.5615 13.7654 38.6508 15.4535C41.8999 16.9959 44.7696 19.179 47.0707 21.8589C49.3717 24.5387 51.0518 27.6543 52 31H42.7758C41.9246 28.094 40.1946 25.4926 37.8026 23.522C35.4105 21.5513 32.463 20.299 29.3292 19.9219V31H28.3223Z" />
    </svg>
  );
};

export const YtLogo = () => {
  return (
    <svg
      width="53"
      height="37"
      viewBox="0 0 53 37"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.0177 1.37587C49.2639 1.97672 51.0349 3.73634 51.6397 5.96806C52.7628 10.0452 52.7196 18.5429 52.7196 18.5429C52.7196 18.5429 52.7196 26.9977 51.6397 31.0749C51.0349 33.3066 49.2639 35.0662 47.0177 35.6671C42.9141 36.74 26.4997 36.74 26.4997 36.74C26.4997 36.74 10.1284 36.74 5.98164 35.6242C3.73545 35.0233 1.96442 33.2637 1.35968 31.032C0.279785 26.9977 0.279785 18.5 0.279785 18.5C0.279785 18.5 0.279785 10.0452 1.35968 5.96806C1.96442 3.73634 3.77865 1.9338 5.98164 1.33295C10.0852 0.26001 26.4997 0.26001 26.4997 0.26001C26.4997 0.26001 42.9141 0.26001 47.0177 1.37587ZM34.9231 18.5002L21.2733 26.3112V10.6891L34.9231 18.5002Z"
      />
    </svg>
  );
};

export const TgLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 512 512"
      fill="currentColor"
    >
      <path d="M477 43.86 13.32 223.29a6 6 0 0 0-.8.38c-3.76 2.13-30 18.18 7 32.57l.38.14 110.41 35.67a6.08 6.08 0 0 0 5.09-.62l273.85-170.86a6 6 0 0 1 2.2-.83c3.81-.63 14.78-1.81 7.84 7-7.85 10-194.9 177.62-215.66 196.21a6.3 6.3 0 0 0-2.07 4.17l-9.06 108a7.08 7.08 0 0 0 2.83 5.67 6.88 6.88 0 0 0 8.17-.62l65.6-58.63a6.09 6.09 0 0 1 7.63-.39l114.45 83.1.37.25c2.77 1.71 32.69 19.12 41.33-19.76l79-375.65c.11-1.19 1.18-14.27-8.17-22-9.82-8.08-23.72-4-25.81-3.56a6 6 0 0 0-.9.33" />
    </svg>
  );
};

export const Restart = () => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.4719 0C9.72924 0 1.80249 7.92675 1.80249 17.6694C1.80249 26.8271 8.85905 34.4857 17.9339 35.2682L14.4361 37.0415C14.0046 37.2791 13.7462 37.8117 14.0034 38.2322L14.1265 38.5515C14.3836 38.9732 14.9406 39.1219 15.3708 38.8854L21.2099 35.8958C21.2172 35.891 21.2245 35.8922 21.233 35.8873L21.6255 35.6728C21.8412 35.5534 21.985 35.3596 22.0423 35.1439C22.102 34.9282 22.0752 34.6868 21.946 34.4772L21.7132 34.0957C21.7096 34.0884 21.7022 34.0836 21.6986 34.0775L18.0923 28.442C17.8364 28.0203 17.2806 27.8716 16.848 28.108L16.5652 28.3055C16.1338 28.5419 16.1045 29.1513 16.3605 29.5705L18.4884 32.8721C18.4774 32.8721 18.4665 32.8672 18.4555 32.866C10.4849 32.3432 4.24243 25.6669 4.24243 17.6682C4.24243 9.26981 11.076 2.43628 19.4744 2.43628C27.874 2.43628 34.7453 9.26859 34.7453 17.6682C34.7453 22.5042 32.5235 26.9466 28.6516 29.8545C28.1129 30.2591 28.0056 31.0233 28.409 31.562C28.8136 32.1007 29.5778 32.2091 30.1165 31.8045C34.6088 28.431 37.184 23.2781 37.184 17.6694C37.184 7.92675 29.2183 0 19.4756 0L19.4719 0Z" />
    </svg>
  );
};

export const VolumeOff = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26.226 3.17592C25.737 2.91642 25.1445 2.94492 24.684 3.25542L9.633 15.0724L4.635 15.0754C2.079 15.0754 0 16.8874 0 19.1254L0.0795 29.0224C0.0795 31.2514 2.1585 33.0649 4.713 33.0649L9.7095 33.0679L24.681 44.9149C24.933 45.0844 25.2255 45.1714 25.5195 45.1714C25.761 45.1714 26.004 45.1129 26.223 44.9959C26.7135 44.7364 27.021 44.2279 27.021 43.6714V4.49892C27.021 3.94542 26.715 3.43542 26.2245 3.17442L26.226 3.17592ZM24.0225 40.8514L11.01 30.3274C10.7625 30.1609 10.47 30.0709 10.1715 30.0709L4.716 30.0679C3.753 30.0679 3.081 29.5189 3.081 29.0149L3 19.1179C3 18.6259 3.672 18.0784 4.635 18.0784L10.092 18.0754C10.3905 18.0754 10.6815 17.9869 10.929 17.8189L24.0225 7.31892V40.8514ZM39.069 24.3454C39.069 20.3614 36.33 17.0119 32.5575 16.3819C31.728 16.2454 30.966 16.7974 30.831 17.6149C30.696 18.4324 31.2465 19.2049 32.064 19.3414C34.4235 19.7344 36.0705 21.7939 36.0705 24.3454C36.0705 26.7754 34.365 28.8769 32.016 29.3464C31.203 29.5084 30.6765 30.2974 30.8385 31.1104C30.981 31.8244 31.6065 32.3179 32.3085 32.3179C32.4045 32.3179 32.505 32.3089 32.604 32.2879C36.351 31.5409 39.072 28.1989 39.072 24.3439L39.069 24.3454ZM37.0785 10.5829C36.303 10.2949 35.4375 10.6879 35.1495 11.4649C34.8585 12.2404 35.2545 13.1044 36.03 13.3939C40.5555 15.0829 43.5945 19.4824 43.5945 24.3454C43.5945 29.2099 40.5555 33.6199 36.033 35.3149C35.2575 35.6074 34.8645 36.4699 35.154 37.2454C35.3805 37.8484 35.952 38.2189 36.5595 38.2189C36.735 38.2189 36.912 38.1889 37.086 38.1229C42.774 35.9899 46.596 30.4534 46.596 24.3439C46.596 18.2344 42.771 12.7054 37.08 10.5814L37.0785 10.5829Z" />
    </svg>
  );
};

export const VolumeOn = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26.9775 3.08852C26.4885 2.82902 25.896 2.85752 25.4355 3.16802L10.3845 14.985L5.38647 14.988C2.83047 14.988 0.751465 16.8 0.751465 19.038L0.830965 28.935C0.830965 31.164 2.90997 32.9775 5.46447 32.9775L10.461 32.9805L25.4325 44.8275C25.6845 44.997 25.977 45.084 26.271 45.084C26.5125 45.084 26.7555 45.0255 26.9745 44.9085C27.465 44.649 27.7725 44.1405 27.7725 43.584V4.41152C27.7725 3.85802 27.4665 3.34952 26.9775 3.08852ZM24.774 40.764L11.7615 30.24C11.514 30.0735 11.2215 29.9835 10.923 29.9835L5.46747 29.9805C4.50447 29.9805 3.83247 29.4315 3.83247 28.9275L3.75147 19.0305C3.75147 18.5385 4.42347 17.991 5.38647 17.991L10.8435 17.988C11.142 17.988 11.433 17.8995 11.6805 17.7315L24.774 7.23152V40.764ZM41.907 24.024L46.8105 19.062C47.3955 18.477 47.3955 17.526 46.8105 16.941C46.2255 16.356 45.276 16.356 44.6895 16.941L39.798 21.8895L34.9065 16.941C34.3215 16.356 33.372 16.356 32.7855 16.941C32.199 17.526 32.2005 18.477 32.7855 19.062L37.6875 24.024L32.8305 28.938C32.2455 29.523 32.2455 30.474 32.8305 31.059C33.4155 31.644 34.3665 31.644 34.953 31.059L39.7965 26.1585L44.64 31.059C45.225 31.644 46.1745 31.644 46.761 31.059C47.3475 30.474 47.346 29.523 46.761 28.938L41.907 24.024Z" />
    </svg>
  );
};

export const ApheristLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210mm"
      height="210mm"
      viewBox="0 0 21000 21000"
    >
      <path
        d="m18050.23 4538.91-3528.01-2.14-369.27-1031.39-77.41 34.28-1247.27 6889.84-69.98-6.35V4536.98h-9877.8V16417.9h1058.33V7636.98h705.56v8780.92h1058.33V7636.98h705.56v8780.92H7466.6V7636.98h705.55v8780.92h2822.25V7636.98h705.56v8780.92l369.2 1032.53 78.18-34.92 1246.61-6526.24 69.93 6.62v5522.01h2170.79v-1058.33h-1112.45V5595.58l1587.5.75V16417.9h1058.33V5596.83l881.7.41.48-1058.33zm-8819.74 1056.4h705.59v983.34h-705.59v-983.34zm-5291.67 983.34v-983.34h705.56v983.34h-705.56zm1763.89 0v-983.34h705.56v983.34h-705.56zm1763.89 0v-983.34h705.55v983.34H7466.6zm2469.48 8780.92h-705.59V7636.98h705.59v7722.59zm1058.32-8780.92v-983.34h705.56v983.34h-705.56z"
        fill="currentColor"
      />
    </svg>
  );
};
