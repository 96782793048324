import { useEffect, useState } from "react";
import { LogoContainer } from "../logo-container/logo-container";
import { PreTitlesScreen } from "./pre-titles-screen/pre-titles-screen";
import { FadeInComponent } from "./fade-in-component/fade-in-component";
import { Glitch } from "../glitch/glitch";
import { Button } from "../button/button";
import { TgLogo, VkLogo } from "../icons/icons";

import uiText from "../../data/ui-text.json";
import { Result as ResultT, ResultTrackNames } from "../../types";

import npcTrack from "../../assets/music/npc.mp3";
import kkTrack from "../../assets/music/kk.mp3";

import s from "./titles-screen.module.css";

// import { SlideShow } from "../slideshow/slideshow";

const FINAL_SONGS: Record<ResultTrackNames, any> = {
  npc: npcTrack,
  kk: kkTrack,
};

type Props = {
  result: ResultT;
  onRestart: () => void;
};

export const TitlesScreen = ({ result, onRestart }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentScreen, setCurrentScreen] = useState<"pretitles" | "titles">(
    "pretitles"
  );

  const onShowTitles = () => {
    setCurrentScreen("titles");
  };

  useEffect(() => {
    if (currentScreen === "titles") {
      setIsVisible(true);
    }
  }, [currentScreen]);

  // Не у всех результатов есть трек-награда, но он есть у тех, для которых мы показываем титры
  // То есть мы не будем показывать титры для результатов без трека-награды
  // Поэтому здесь ??, просто чтобы не морочиться с undefined состоянием audioTrackName у result
  const resultTrackName = result.audioTrackName ?? "npc";

  return (
    <>
      {currentScreen === "titles" && (
        <FadeInComponent isVisible={isVisible} transitionDuration={2000}>
          <div className={s.root}>
            <div className={s.logoArea}>
              <LogoContainer gameScreen="titles-screen" />
            </div>
            <div className={s.content}>
              <div className={s.imgContainer}>
                <Glitch srcPath="/images/apherist_root.webp" />
                {/* <SlideShow /> */}
              </div>
              <div className={s.textContent}>
                <div className={s.audioPlayerContainer}>
                  <audio
                    controls
                    src={FINAL_SONGS[resultTrackName]}
                    controlsList="noplaybackrate nodownload"
                  />
                </div>
                <p
                  className={s.description}
                  dangerouslySetInnerHTML={{
                    __html: result.endingScreenDescription,
                  }}
                />
                <p
                  className={s.releaseInfo}
                  dangerouslySetInnerHTML={{
                    __html: uiText.endScreen.releaseInfo,
                  }}
                />
                <div className={s.socialsContainer}>
                  <a
                    href="https://vk.com/apheristlovefist"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <VkLogo />
                  </a>
                  <a
                    href="https://t.me/apherist2347"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TgLogo />
                  </a>
                </div>
                <ul className={s.authorsList}>
                  {uiText.endScreen.authors.map((el, idx) => (
                    <li key={idx}>
                      <span>{el.job}</span>
                      <span className={s.name}>{el.name}</span>
                    </li>
                  ))}
                </ul>
                <div className={s.restartButtonContainer}>
                  <Button size="big" onClick={onRestart}>
                    {uiText.endScreen.restartButtonLabel}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </FadeInComponent>
      )}
      {currentScreen === "pretitles" && (
        <PreTitlesScreen onShowTitles={onShowTitles} />
      )}
    </>
  );
};
