import { useState, useEffect, useContext } from "react";
import { Question as QuestionT } from "../../types";

import s from "./question.module.css";
import { Button } from "../button/button";
import { SoundtrackContext } from "../../contexts/soundtrack-context";
import { getNextIdForNumberInputQuestion } from "../../helpers/get-next-id-for-number-input-question";
import { Glitch } from "../glitch/glitch";

type Props = {
  question: QuestionT;
  onNext: (id: string) => void;
  onRestart: () => void;
};

export const Question = ({ question, onNext, onRestart }: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const { currentTrack, switchMusic } = useContext(SoundtrackContext);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    setIsVisible(true);
  }, [question, switchMusic]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [question]);

  useEffect(() => {
    if (currentTrack !== question.audioTrackName)
      switchMusic(question.audioTrackName);
  }, [question, currentTrack, switchMusic]);

  /* TODO: Вынести в отдельное место 
    Это логика для вопроса с инпутом
  */
  const [value, setValue] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Use a regular expression to allow only digits
    const digitsOnly = value.replace(/\D/g, "");
    const twoNumbersOnly = digitsOnly.slice(0, 2);

    setValue(twoNumbersOnly);
  };

  return (
    <div className={s.root} data-visible={isVisible}>
      <div className={s.content}>
        <div className={s.imgContainer}>
          {question.isGlitch ? (
            <Glitch srcPath={`/images/${question.imageName}.webp`} />
          ) : (
            <img src={`/images/${question.imageName}.webp`} alt="" />
          )}
        </div>
        <div className={s.textContent}>
          <div className={s.description}>
            {question.description.map((el, idx) => (
              <p key={idx}>{el}</p>
            ))}
          </div>
          {question.type === "basic" && (
            <div className={s.answers}>
              {question.options.map((el, idx) => (
                <Button
                  key={idx}
                  size="big"
                  onClick={() => {
                    setIsVisible(false);
                    setTimeout(() => onNext(el.nextId), 300);
                  }}
                >
                  {el.label}
                </Button>
              ))}
            </div>
          )}
          {question.type === "with-number-input" && (
            <div className={s.numberInputGrid}>
              <input
                type="tel"
                value={value}
                onChange={handleInputChange}
                placeholder="Введите этаж"
                className={s.numberInput}
              />
              <Button
                size="big"
                disabled={value === ""}
                onClick={() => {
                  setIsVisible(false);
                  setTimeout(() => {
                    onNext(
                      getNextIdForNumberInputQuestion({
                        data: {
                          defaultNextId: question.defaultNextId,
                          nextIdsByAnswer: question.nextIdsByAnswer,
                        },
                        userInput: value,
                      })
                    );
                    setValue("");
                  }, 300);
                }}
              >
                OK
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
