import { Questions, Results } from "../types";

export const questionsData: Questions = {
  q_0: {
    type: "basic",
    description: [
      "Вы просыпаетесь от раздражающего звона будильника. Начинается очередной будний день.",
      "Совершив утренний ритуал пробуждения, а также все сопутствующие ему действия, вы отправляетесь в офис.",
    ],
    options: [
      {
        label: "Пойти на работу",
        nextId: "q_1_1",
      },
    ],
    imageName: "q_0",
    audioTrackName: "main-theme",
  },

  q_1_1: {
    type: "basic",
    description: [
      "Дорога до офиса занимает привычный час.",
      "Вашу работу нельзя назвать особо полезной. Неудобное расположение и низкая оплата труда не добавляют ей шарма.",
      "С первых же дней вы невзлюбили новую работу, но, так и не сумев найти ничего лучше, смирились со своей участью.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_1_2",
      },
    ],
    imageName: "q_1_1",
    audioTrackName: "main-theme",
  },

  q_1_2: {
    type: "basic",
    description: [
      "2023.05.** —  “Полиграфия города ******”",
      "За размышлениями о предстоящем дне вы достигли двери офиса.",
      "Вас встречает небольшое помещение, грязный линолеум и свет люминесцентных ламп. Постепенно комнату заполняет жужжание полиграфического оборудования.",
      "Начало дня проходит без клиентов. Вы погружаетесь в разбор корпоративных рассылок.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_2",
      },
    ],
    imageName: "q_1_2",
    audioTrackName: "main-theme",
  },

  q_2: {
    type: "basic",
    description: [
      "Внезапно ваше внимание привлекает звук дверного колокольчика. В офис заходит потенциальный клиент и, сразу устремившись в вашу сторону, начинает говорить.",
      "> (клиент) Добрый день, мне нужно...",
      "Вы присматриваетесь к собеседнику. Перед вами высокий худощавый мужчина с невыразительными чертами лица. Ничем не примечательный внешний вид, а также порванный черный пакет в левой руке выдают в нем рядового посетителя.",
      "> (вы) А?",
      "> (клиент) Здравствуйте, я бы хотел распечатать файл с флешки.",
      "> (вы) Хорошо, вставляйте в разъем на стойке.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_3",
      },
    ],
    imageName: "q_2",
    audioTrackName: "main-theme",
  },

  q_3: {
    type: "basic",
    description: [
      "После непродолжительной возни с USB-разъемом на экране появляется список файлов.",
      "Что ж, дальнейший порядок действий прост: поднять голову, задать вопрос «Какой файл вам нужен?», затем...",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_4",
      },
    ],
    imageName: "q_3",
    audioTrackName: "main-theme",
  },

  q_4: {
    type: "basic",
    description: [
      "Скоп глитчей на мониторе выбивает вас из колеи. Комната незаметно начинает погружаться в сумрак.",
    ],
    isGlitch: true,
    options: [
      {
        label: "«КАКОГО ХУЯ?»",
        nextId: "q_5",
      },
      {
        label: "«Какой файл вам нужен?»",
        nextId: "q_16",
      },
    ],
    imageName: "q_4",
    audioTrackName: "cursed-theme",
  },

  q_5: {
    type: "basic",
    description: [
      "Вы вскакиваете с кресла, не отрывая взгляд от монитора. На экране появляется длинный hex-код, и принтер начинает печатать его сам по себе.",
    ],
    isGlitch: true,
    options: [
      {
        label: "<далее>",
        nextId: "q_6",
      },
    ],
    imageName: "q_5",
    audioTrackName: "cursed-theme",
  },

  q_6: {
    type: "basic",
    description: [
      "> (клиент) Слышь, долбоеб, ты кого на хуй послал? Бросай все, пойдем поговорим.",
      "Воздух в помещении заметно потяжелел. Вы поворачиваетесь к клиенту, чтобы успокоить агрессора и замять ситуацию, но от увиденного вам становится не по себе.",
      "Осунувшееся, мертвецки бледное лицо вашего собеседника корчится в пугающей гримасе. Лоб покрывается вздутыми пульсирующими венами.",
      "Вы смотрите в его широко открытые остекленевшие глаза и отчетливо ощущаете нарастающее чувство страха.",
    ],
    options: [
      {
        label: "Закричать",
        nextId: "q_7",
      },
      {
        label: "Атаковать ПОСЕТNTEЛR",
        nextId: "q_8",
      },
    ],
    imageName: "q_6",
    audioTrackName: "cursed-theme",
    isGlitch: true,
  },

  q_7: {
    type: "basic",
    description: [
      "Под ваш крик пространство начинает расходиться по швам. Привычное окружение превращается в бесформенную массу.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_0",
      },
    ],
    imageName: "q_7",
    audioTrackName: "cursed-theme",
    isGlitch: true,
  },

  q_8: {
    type: "basic",
    description: [
      "Вы нервно пробегаетесь взглядом по комнате и замечаете канцелярские ножницы, лежащие на краю стола.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_9",
      },
    ],
    imageName: "q_8",
    audioTrackName: "cursed-theme",
    isGlitch: true,
  },

  q_9: {
    type: "basic",
    description: [
      "Недолго думая, вы хватаете их и со всего размаха всаживаете в голову существа.",
      "Заостренное лезвие попадает прямо в глаз. Не оказав никакого сопротивления, существо делает несколько шагов назад и, корчась от боли, падает на пол.",
      "Спустя пару мгновений все звуки стихают. Удивительно, но распластавшееся на полу тело ПОCETNTEЛR нисколько вас не смущает.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_10",
      },
    ],
    imageName: "q_9",
    audioTrackName: "cursed-theme",
    isGlitch: true,
  },

  q_10: {
    type: "basic",
    description: [
      "Офис тут же начинает наполняться привычным светом ламп. Вы смотрите на труп под ногами. Чувство реальности возвращается.",
      "Внезапно ваше внимание привлекает звук дверного колокольчика. В помещение входит очередной заурядный клиент.",
      "Взгляд посетителя падает на труп в центре комнаты, а затем на вас. Нельзя точно сказать, что пугает его больше: тело на полу с торчащими из глаза ножницами или ваш спокойной вид.",
      "Мужчина тут же достает телефон и начинает судорожно бить по кнопкам.",
      "> (клиент) Алло, полиция? Я нахожусь...",
    ],
    options: [
      {
        label: "Вскочить и выбежать из офиса",
        nextId: "q_11",
      },
      {
        label: "Продолжить работать",
        nextId: "q_13",
      },
    ],
    imageName: "q_10",
    audioTrackName: "main-theme",
  },

  q_11: {
    type: "basic",
    description: [
      "Вид встревоженного мужчины и звонок в полицию выводят вас из себя. Не желая лишних проблем и разговоров, вы принимаете решение незамедлительно сбежать...",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_12",
      },
    ],
    imageName: "q_11",
    audioTrackName: "main-theme",
  },

  q_12: {
    type: "basic",
    description: [
      "Вы вылетаете из офиса и бежите к противоположной стороне улицы. Оглядываясь, вы не замечаете, как попадаете на проезжую часть.",
      "Краем глаза вы видите приближающийся грузовик. Глухой удар, хруст костей...",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_0",
      },
    ],
    imageName: "q_12_26",
    audioTrackName: "main-theme",
  },

  q_13: {
    type: "basic",
    description: [
      "Вид суетящегося мужчины начинает вас раздражать. Вы усаживаетесь обратно в кресло и, пока клиент говорит по телефону, продолжаете проверять корпоративную рассылку.",
      "Как только посетитель кладет трубку, вы начинаете обыкновенный рабочий диалог.",
      "> (вы) Добрый день! Что Вас интересует?",
      "Вы видите, как мужчина продолжает молча стоять на пороге и смотреть на вас. Странный тип.",
      "Еще какое-то время вы занимаетесь своими делами, как вдруг комнату наполняют люди в форме.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_14",
      },
    ],
    imageName: "q_10",
    audioTrackName: "main-theme",
  },

  q_14: {
    type: "basic",
    description: ["..."],
    options: [
      {
        label: "<далее>",
        nextId: "q_15",
      },
    ],
    imageName: "q_14",
    audioTrackName: "main-theme",
  },

  q_15: {
    type: "basic",
    description: ["...", "..."],
    options: [
      {
        label: "<далее>",
        nextId: "r_1",
      },
    ],
    imageName: "q_15",
    audioTrackName: "main-theme",
  },

  q_16: {
    type: "basic",
    description: [
      "Не поднимая головы, вы плотно закрываете глаза и переспрашиваете.",
      "> (вы) Какой файл вам нужен?",
      "...",
      "> (вы) Какой файл ВАМ НУЖЕН?",
      "Ответа по-прежнему нет. Вы сидите еще какое-то время и наконец решаетесь открыть глаза.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_17",
      },
    ],
    imageName: "q_16",
    audioTrackName: "cursed-theme",
    isGlitch: true,
  },

  q_17: {
    type: "basic",
    description: [
      "> (клиент) Договор купли-продажи, пожалуйста.",
      "Вокруг все тот же офис и жужжащие под потолком лампы.",
      "Перед вами привычный проводник Windows, а над стойкой, пытаясь заглянуть в монитор, нависает клиент.",
    ],
    options: [
      {
        label: "Распечатать договор и предложить файлик",
        nextId: "q_18",
      },
      {
        label: "Спросить у клиента, видел ли он что-нибудь странное",
        nextId: "q_43",
      },
    ],
    imageName: "q_2",
    audioTrackName: "main-theme",
  },

  q_18: {
    type: "basic",
    description: [
      "Вы отдаете файлик с договором, мужчина расплачивается.",
      "> (клиент) Спасибо.",
      "> (вы) Не за что, заходите еще.",
      "Колокольчик над дверью звенит, провожая клиента.",
      "4ТО 3Т0 6Ь|Л0?",
      "Неважно. Вы взглянули на время: до обеда осталось полчаса.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_19",
      },
    ],
    imageName: "q_1_2",
    audioTrackName: "main-theme",
  },

  q_19: {
    type: "basic",
    description: [
      "Остаток времени вы продолжаете разгребать корпоративную почту.",
      "Наконец дождавшись перерыва, вы хватаете пальто, запираете дверь в офис и направляетесь в сторону кофейни.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_20",
      },
    ],
    imageName: "q_19",
    audioTrackName: "main-theme",
  },

  q_20: {
    type: "basic",
    description: [
      "Вы заходите в кофейню и тут же чувствуете приятный аромат кофе.",
      "На стене за кассой висит доска с весьма широким ассортиментом. Вы рассматриваете множество изысканных и замысловатых названий и пытаетесь выбрать напиток.",
      "Бариста смотрит на вас унылым взглядом, ожидая вашего выбора.",
    ],
    options: [
      {
        label: "Продолжить выбирать",
        nextId: "q_21",
      },
      {
        label: "«Американо, пожалуйста»",
        nextId: "q_24",
      },
    ],
    imageName: "q_20",
    audioTrackName: "main-theme",
  },

  q_21: {
    type: "basic",
    description: ["Вы продолжаете внимательно изучать меню."],
    options: [
      {
        label: "«Раф, пожалуйста»",
        nextId: "q_22",
      },
    ],
    imageName: "q_21",
    audioTrackName: "main-theme",
  },

  q_22: {
    type: "basic",
    description: [
      "Вы расплачиваетесь и через некоторое время получаете свой напиток. Пора возвращаться в офис.",
      "Вы благодарите бариста и выходите из кофейни.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_23",
      },
    ],
    imageName: "q_22_24",
    audioTrackName: "main-theme",
  },

  q_23: {
    type: "basic",
    description: [
      "Тут же на крыльце в вас стремительно врезается мужчина в белой рубашке. Тело пронзает боль. Вы опускаете взгляд и видите торчащий в животе нож.",
      "Незнакомец крепко сжимает рукоять обеими руками и оскалившись смотрит на вас. Все вокруг начинает плыть перед глазами. Тело тяжелеет, вы закрываете глаза...",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_0",
      },
    ],
    imageName: "q_23",
    audioTrackName: "main-theme",
  },

  q_24: {
    type: "basic",
    description: [
      "Вы расплачиваетесь и через некоторое время получаете свой напиток. Пора возвращаться в офис.",
      "Вы благодарите бариста и выходите из кофейни.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_25",
      },
    ],
    imageName: "q_22_24",
    audioTrackName: "main-theme",
  },

  q_25: {
    type: "basic",
    description: [
      "У входа стоит худощавый мужчина в белой рубашке и с довольной ухмылкой смотрит прямо на вас.",
      "Едва вы успеваете сделать шаг, как замечаете блеснувшее в руке незнакомца лезвие. Он срывается с места и стремительно летит в вашу сторону.",
    ],
    options: [
      {
        label: "Сбежать",
        nextId: "q_26",
      },
      {
        label: "Присмотреться к незнакомцу",
        nextId: "q_27",
      },
    ],
    imageName: "q_25",
    audioTrackName: "coffee-streetfight-theme",
  },

  q_26: {
    type: "basic",
    description: [
      "Недолго думая, вы разворачиваетесь и начинаете бежать.",
      "Оглядываясь, вы не замечаете, как попадаете на проезжую часть.",
      "Краем глаза вы видите приближающийся грузовик. Глухой удар, хруст костей...",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_0",
      },
    ],
    imageName: "q_12_26",
    audioTrackName: "coffee-streetfight-theme",
  },

  q_27: {
    type: "basic",
    description: [
      "Лицо приближающегося человека кажется вам знакомым. Вы изо всех сил стараетесь вспомнить, где могли его видеть, но понимаете, что замешкались.",
      "Очевидно, шанс сбежать упущен. Нужно спасать свою жизнь.",
    ],
    options: [
      {
        label: "Вступить в драку",
        nextId: "q_28",
      },
    ],
    imageName: "q_27",
    audioTrackName: "coffee-streetfight-theme",
  },

  q_28: {
    type: "basic",
    description: [
      "Мужчина уже находится в паре метров от вас и продолжает сокращать дистанцию. Вы инстинктивно вытягиваете руки вперед, пытаясь защититься. Несмотря на это, нападающий врезается в вас и заносит нож. Под мощью удара вы отпрянули назад — лезвие застряло в вашем плече.",
      "Оставшись без оружия, незнакомец замирает на месте. Вы решаете воспользоваться его замешательством и, вырвав нож из плеча, одним броском сокращаете расстояние между вами.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_29",
      },
    ],
    imageName: "q_28",
    audioTrackName: "coffee-streetfight-theme",
  },

  q_29: {
    type: "basic",
    description: [
      "Вы наносите удар. Лезвие, не встречая сопротивления, проникает глубоко в шею. Тело вашего оппонента обмякло и замертво рухнуло на тротуар.",
      "Вам удалось избежать смерти, но что делать дальше?",
    ],
    options: [
      {
        label: "Осмотреть тело",
        nextId: "q_31",
      },
      {
        label: "Попытаться скрыться",
        nextId: "q_30",
      },
    ],
    imageName: "q_29",
    audioTrackName: "coffee-streetfight-theme",
  },

  q_30: {
    type: "basic",
    description: [
      "Не сумев совладать с нарастающей тревогой, вы решаете бежать.",
      "В панике вы несетесь вперед, сменяя одну улицу за другой. Вы бежите еще какое-то время, но вдруг замечаете преследующих вас полицейских.",
      "Они догоняют вас и валят на землю...",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_14",
      },
    ],
    imageName: "q_30",
    audioTrackName: "coffee-streetfight-theme",
  },

  q_31: {
    type: "basic",
    description: [
      "Осознав что вашей жизни ничего не угрожает, вы немного успокаиваетесь.",
      "Вы не можете отделаться от мысли, что уже где-то видели этого человека, но у вас не получается вспомнить кому именно принадлежит это лицо.",
      "Решив во всем разобраться, вы наклоняетесь к телу, чтобы осмотреть его.",
      "Чем дольше вы всматриваетесь в лицо трупа, тем больше убеждаетесь в том, что оно похоже на ваше...",
    ],
    options: [
      {
        label: "Закричать",
        nextId: "q_7",
      },
      {
        label: "Собраться",
        nextId: "q_32",
      },
    ],
    imageName: "q_31",
    audioTrackName: "coffee-streetfight-theme",
  },

  q_32: {
    type: "basic",
    description: [
      "Вы закрываете глаза и делаете глубокий вдох. Ноющая боль тут же напоминает о недавнем ранении. Вы достаете из кармана платок и туго затягиваете им плечо.",
      "Успокоившись, вы еще раз бросаете взгляд на лежащее тело и понимаете, что только что совершили убийство. У вас нет никакого желания отвечать за этот поступок.",
      "Вы перебираете в голове множество вариантов. Нужно что-то предпринять...",
    ],
    options: [
      {
        label: "Арендовать минивэн в каршеринге, чтобы спрятать труп",
        nextId: "q_34",
      },
      {
        label: "Оттащить труп в офисную подсобку, чтобы все спокойно обдумать",
        nextId: "q_33",
      },
    ],
    imageName: "q_31",
    audioTrackName: "coffee-streetfight-theme",
  },

  q_33: {
    type: "basic",
    description: [
      "Вы наклоняетесь к трупу, пытаясь его приподнять и перекинуть через плечо, но он чересчур податлив и валится из рук. Не добившись успеха, вы хватаете мертвеца за ноги и начинаете тащить его в сторону офиса. Тяжелое тело постоянно цепляется за выступы тротуарных плит, что значительно усложняет задачу.",
      "Спустя некоторое время вы наконец дотаскиваете труп до входа. Остановившись, вы понимаете, что все это время он оставлял за собой кровавый след, и видите, как из-за угла здания выходят двое полицейских.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_14",
      },
    ],
    imageName: "q_33",
    audioTrackName: "coffee-streetfight-theme",
  },

  q_34: {
    type: "basic",
    description: [
      "Ваше внимание привлекает припаркованный поблизости каршеринг-минивэн.",
      "На вас тут же снисходит озарение. Не теряя ни минуты, вы открываете приложение и арендуете машину.",
      "С трудом погрузив тело в багажник, вы садитесь за руль и срываетесь с места.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_35",
      },
    ],
    imageName: "q_34",
    audioTrackName: "main-theme",
  },

  q_35: {
    type: "basic",
    description: [
      "Спустя час езды вы оказываетесь на окружной дороге и направляетесь в сторону пригорода.",
      "Наконец-то вам удается немного расслабиться и спокойно обдумать, что делать дальше.",
    ],
    options: [
      {
        label: "Сжечь труп",
        nextId: "q_36",
      },
      {
        label: "Скинуть труп в реку",
        nextId: "q_39",
      },
    ],
    imageName: "q_35",
    audioTrackName: "main-theme",
  },

  q_36: {
    type: "basic",
    description: [
      "Вы несетесь по шоссе, как вдруг вдалеке замечаете заправку и сразу же понимаете, что в суматохе не подумали, каким образом будете сжигать тело.",
      "Вы съезжаете на заправку и останавливаетесь у дальней колонки, опасаясь привлечь лишнее внимание.",
      "Купив бензин, вы запрыгиваете обратно в машину и уезжаете.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_37",
      },
    ],
    imageName: "q_36",
    audioTrackName: "main-theme",
  },

  q_37: {
    type: "basic",
    description: [
      "Вы продвигаетесь по шоссе в поисках подходящего места. Обнаружив неприметный поворот, вы съезжаете с трасы и направляетесь вглубь лесопосадки по разбитой проселочной дороге.",
      "Спустя какое-то время вы упираетесь в небольшую поляну. Остановившись у её края, вы выходите из машины, достаете труп из багажника и оттаскиваете его в глубь лесного массива.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_38",
      },
    ],
    imageName: "q_37",
    audioTrackName: "main-theme",
  },

  q_38: {
    type: "basic",
    description: [
      "Забрав бензин, вы возвращаетесь к оставленному в небольшой ложбине телу.",
      "Вы оглядываетесь по сторонам и, убедившись в отсутствии свидетелей, открываете канистру и обливаете труп.",
      "Вы достаете зажигалку из пальто, подносите к телу, и в тот же момент его охватывает пламя. Некоторое время вы спокойно наблюдаете за огнем, однако игнорировать едкий запах горящей плоти становится все сложнее.",
      "Вы делаете несколько шагов назад, но это не помогает: запах становится невыносимым. Головокружение усиливается, а в глазах начинает темнеть. Вы теряете сознание...",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_0",
      },
    ],
    imageName: "q_38",
    audioTrackName: "main-theme",
  },

  q_39: {
    type: "basic",
    description: [
      "Пройдясь глазами по карте, вы замечете, что недалеко от города есть мост.",
      "Вы бросаете телефон на соседнее кресло и направляетесь к нему.",
    ],
    options: [
      {
        label: "Продолжить путь",
        nextId: "q_41",
      },
    ],
    imageName: "q_39",
    audioTrackName: "main-theme",
  },

  q_41: {
    type: "basic",
    description: [
      "Наконец вы видите приближающиеся огни.",
      "Вы останавливаетесь на середине моста, включаете аварийку и выходите из машины.",
      "Повозившись с телом, вы все же дотаскиваете его до края, перекидываете через перила и сбрасываете вниз.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_42",
      },
    ],
    imageName: "q_41",
    audioTrackName: "main-theme",
  },

  q_42: {
    type: "basic",
    description: [
      "Труп с громким всплеском погружается в воду и тут же поднимается на поверхность.",
      "Какое-то время вы наблюдаете за тем, как незнакомца уносит течением. Проводив тело взглядом, вы опираетесь на отбойник и вздыхаете.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "r_2",
      },
    ],
    imageName: "q_42",
    audioTrackName: "main-theme",
  },

  q_43: {
    type: "basic",
    description: [
      "> (вы) Извините, а вы сейчас ничего странного не заметили?",
      "Вы отрываете взгляд от монитора и видите перед собой пустой офис. Не понимая, что происходит, вы встаете с кресла и проходитесь по помещению. Никаких признаков пребывания клиента — но флешка все еще на месте.",
      "> (вы) Что за... Ладно, похуй.",
      "Вы возвращаетесь за компьютер.",
    ],
    options: [
      {
        label: "Изучить содержимое флешки",
        nextId: "q_44",
      },
      {
        label: "Выбросить из головы и продолжить работать",
        nextId: "q_45_1",
      },
    ],
    imageName: "q_1_2",
    audioTrackName: "main-theme",
  },

  q_44: {
    type: "basic",
    description: [
      "Среди кучи excel-файлов с названиями вроде «Отчет 2022 (1) (1).xlsx» вы обнаруживаете совершенно NН0Й ФVЙЛ неизвестного формата: «Приглашение — Корпорация Аферист».",
    ],
    options: [
      {
        label: "«На сегодня достаточно странностей»",
        nextId: "q_45_1",
      },
      {
        label: "Попытаться открыть файл",
        nextId: "q_47",
      },
    ],
    imageName: "q_44",
    audioTrackName: "main-theme",
  },

  q_45_1: {
    type: "basic",
    description: [
      "Вы вынимаете флешку из разъема и бросаете ее в коробку к другим забытым накопителям. Выбросив из головы произошедшее, вы открываете почту и начинаете разгребать заказы. К обеду появляются новые клиенты.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_45_2",
      },
    ],
    imageName: "q_45_1",
    audioTrackName: "main-theme",
  },

  q_45_2: {
    type: "basic",
    description: [
      "День наполняется привычной рутиной: документами, версткой печатей и дизайном визиток.",
      "Покончив с последним заказом, вы выключаете всю технику и покидаете офис.",
    ],
    options: [
      {
        label: "Отправиться домой и лечь спать",
        nextId: "q_0",
      },
      {
        label: "Зайти за электронной сигаретой",
        nextId: "q_46_1",
      },
    ],
    imageName: "q_45_2",
    audioTrackName: "main-theme",
  },

  q_46_1: {
    type: "basic",
    description: [
      "По дороге вы решаете зайти в табачный магазин поблизости. Вы приобретаете электронную сигарету на 2347 затяжек и направляетесь домой.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_46_2",
      },
    ],
    imageName: "q_46_1",
    audioTrackName: "main-theme",
  },

  q_46_2: {
    type: "basic",
    description: [
      "Путь от метро занимает около 10 минут. Наконец вы попадаете в знакомый закрытый двор старых, посеревших домов.",
      "Вы проходите мимо разбитых деревянных лавочек, останавливаетесь у своего подъезда и, замешкавшись на крыльце, решаете покурить.",
      "Вдоволь накурившись, вы достаете ключи и заходите в подъезд.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_46_3",
      },
    ],
    imageName: "q_46_2",
    audioTrackName: "main-theme",
  },

  q_46_3: {
    type: "basic",
    description: [
      "Подъездное помещение встречает вас привычным полумраком и затхлым запахом сырости.",
      "Вы проходите несколько лестничных пролетов, как вдруг останавливаетесь. Внезапный приступ головокружения не дает вам сдвинуться с места.",
      "> (вы) Ох... Зря я покурил...",
      "Вы пытаетесь сделать еще один шаг и сесть на ступеньки, но теряете ориентацию в пространстве и опрокидываетесь назад.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_46_4",
      },
    ],
    imageName: "q_46_3",
    audioTrackName: "main-theme",
  },

  q_46_4: {
    type: "basic",
    description: [
      "Набирая скорость, ваше тело летит вниз на лестничную площадку. Вся сила удара приходится в область головы, тишину подъезда обрывает противный хруст.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "r_3",
      },
    ],
    imageName: "q_46_4",
    audioTrackName: "main-theme",
  },

  q_47: {
    type: "basic",
    description: [
      "Вам удается открыть приглашение в «Блокноте»:",
      "<Добрый день, Ва***тин.",
      "Мы хотели бы пригласить ВАС в наш офис по адресу город ******, улица **********, дом 23, этаж 47.",
      "Просим вас как можно БЫСТРЕЕ рассмотреть приглашение и явиться по указанному адресу.",
      "Спасибо>",
      "Обращение вводит вас в ступор. Вы чувствуете, что ввязываетесь в весьма странную ситуацию.",
    ],
    options: [
      {
        label: "«Ну его на хуй...»",
        nextId: "q_45_1",
      },
      {
        label: "Распечатать приглашение",
        nextId: "q_48",
      },
    ],
    imageName: "q_47",
    audioTrackName: "main-theme",
  },

  q_48: {
    type: "basic",
    description: [
      "Вы складываете лист с приглашением вдвое и кладете его в пальто. Нужно дождаться вечера.",
      "Вы садитесь на свое место и пытаетесь поработать, но мысль о странном письме не выходит из головы.",
      "Написав менеджеру, что вам нужно уйти пораньше, вы хватаете пальто и закрываете офис.",
    ],
    options: [
      {
        label: "Поехать на метро",
        nextId: "q_49",
      },
      {
        label: "Поехать на такси",
        nextId: "q_50",
      },
    ],
    imageName: "q_48",
    audioTrackName: "main-theme",
  },

  q_49: {
    type: "basic",
    description: [
      "По дороге к метро вам встречается кофейня. Взглянув на вывеску, вы решаете заскочить за стаканчиком кофе.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_20",
      },
    ],
    imageName: "q_22_24",
    audioTrackName: "main-theme",
  },

  q_50: {
    type: "basic",
    description: [
      "Вы выходите в обшарпанный коридор и направляетесь в сторону окна. Расположившись на подоконнике, вы открываете приложение, набираете уже заученный наизусть адрес и вызываете такси.",
      "Вы коротаете время за разглядыванием снующих по улице прохожих, как вдруг из-за угла появляется машина.",
      "Опасаясь разгневать водителя, вы бегом спускаетесь по лестнице и запрыгиваете в авто.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_51",
      },
    ],
    imageName: "q_50",
    audioTrackName: "office-theme",
  },

  q_51: {
    type: "basic",
    description: [
      "Спустя полчаса дороги таксист останавливает машину.",
      "> (водитель) Приехали.",
      "> (вы) Ага... Спасибо. Всего доброго!",
      "Вы вываливаетесь на улицу, и перед вами предстает огромный бизнес-центр престиж-класса.",
      "Вы поднимаете голову и в свете нескольких прожекторов видите стелу с надписью внушительных размеров: «АФЕРИСТ™».",
      "> (вы) Классный дизайн.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_52",
      },
    ],
    imageName: "q_51",
    audioTrackName: "office-theme",
  },

  q_52: {
    type: "basic",
    description: [
      "Вы какое-то время стоите у входа, наблюдая за небольшой компанией людей в холле. Они что-то бурно обсуждают и периодически заливаются смехом, то и дело в шутку толкая друг друга.",
      "С того самого момента, как вы приблизились к этому зданию, вы испытываете смешанные чувства. Вам кажется, что пребывание в подобном месте вам не по статусу.",
      "Вы полны сомнений и уже начинаете думать, что приглашение отправлено вам по ошибке.",
    ],
    options: [
      {
        label: "Войти в здание",
        nextId: "q_54",
      },
      {
        label: "Отправиться домой и попутно купить электронную сигарету",
        nextId: "q_53",
      },
    ],
    imageName: "q_52",
    audioTrackName: "office-theme",
  },

  q_53: {
    type: "basic",
    description: [
      "Отчаявшись, вы направляетесь в сторону метро. По дороге вам встречается табачный магазин. Там вы приобретаете электронную сигарету на 2347 затяжек и направляетесь домой.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_46_2",
      },
    ],
    imageName: "q_46_1",
    audioTrackName: "main-theme",
  },

  q_54: {
    type: "basic",
    description: [
      "Любопытство берет верх, и вы все-таки решаетесь войти внутрь.",
      "Вас встречает роскошный, поражающий своими размерами холл бизнес-центра. Беспокойная компания продолжает что-то бурно обсуждать и не обращает на вас никакого внимания.",
      "В конце помещения, прямо перед лифтами, расположилась широкая стойка ресепшена.",
    ],
    options: [
      {
        label: "Направиться к стойке",
        nextId: "q_55",
      },
    ],
    imageName: "q_54",
    audioTrackName: "office-theme",
  },

  q_55: {
    type: "basic",
    description: [
      "Неуверенным шагом вы направляетесь к стойке регистрации и замечаете аккуратно одетого мужчину. Вы всматриваетесь в его лицо, но не можете вспомнить, где именно его видели. Мужчина резко обрывает ваши размышления.",
      "> (мужчина) Ваше приглашение?",
      "> (вы) А... да, секунду.",
      "Вы запускаете руку во внутренний карман пальто в поисках приглашения, но вместо этого натыкаетесь на небольшой незнакомый предмет. Вы достаете его из кармана и крутите в руках. Это зажигалка.",
      "> (мужчина) В этот раз она Вам не понадобится.",
      "> (вы) Эм-м-м... Хм-м-м... А, вот!",
      "Вы передаете приглашение собеседнику.",
      "> (мужчина) Проходите, Вас уже ждут.",
      "> (вы) Спасибо...",
    ],
    options: [
      {
        label: "Пройти к лифтам",
        nextId: "q_56",
      },
    ],
    imageName: "q_55",
    audioTrackName: "office-theme",
  },

  q_56: {
    type: "basic",
    description: [
      "Поблагодарив человека на ресепшене, вы подходите к лифтам и нажимаете на кнопку вызова. На небольшом табло тут же замелькали номера этажей.",
      "Ожидая лифт, вы почувствовали на себе чей то взор. Вы оборачиваетесь и тут же встречаетесь с устремленными к вам неподвижными взглядами людей из некогда веселой компании. Вы ощущаете, как по вашей спине пробегает холодок.",
      "Нарастающее напряжение обрывает звук прибывшего лифта.",
    ],
    options: [
      {
        label: "Войти в лифт",
        nextId: "q_57",
      },
    ],
    imageName: "q_56",
    audioTrackName: "office-cursed-theme",
    isGlitch: true,
  },

  q_57: {
    type: "basic",
    description: [
      "Двери лифта резко захлопываются, как только вы заходите внутрь. Оглушающий звук удара створок эхом отдается в ваших ушах.",
      "Когда гул прекращается, вы замечаете, что из окружения пропали все звуки.",
      "В это же мгновение в памяти всплывает утренний случай в офисе, и вы вздрагиваете.",
      "Чувство потустороннего не отпускает вас.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_58",
      },
    ],
    imageName: "q_57",
    audioTrackName: "office-cursed-theme",
    isGlitch: true,
  },

  q_58: {
    type: "with-number-input",
    description: [
      "Вы наконец собрались с мыслями и развернулись к панели управления со множеством кнопок: верхняя часть с цифрами от 1 до 8, обозначающая десятки, и нижняя часть с цифрами от 0 до 9 — единицы.",
      "Такую систему вы видите впервые.",
      "> (вы) Блядь... Какой там этаж?",
      "Внезапно вы вспоминаете, что нужная информация есть в приглашении, но надежда тут же угасает: вы отдали его человеку на ресепшене.",
      "Еще какое-то время вы отчаянно пытаетесь восстановить в памяти номер нужного этажа — и, кажется, уже готовы приступить к набору.",
    ],
    defaultNextId: "q_59",
    nextIdsByAnswer: [
      {
        answer: "23",
        next_id: "q_60",
      },
      {
        answer: "47",
        next_id: "q_61",
      },
    ],
    imageName: "q_58",
    audioTrackName: "office-cursed-theme",
    isGlitch: true,
  },
  q_59: {
    type: "basic",
    description: [
      "Лифт устремляется вверх, вжимая вас в пол.",
      "Поездка проходит быстро. С грохотом лифт резко тормозит. Короткий сигнал оповещает вас о прибытии.",
      "Створки распахиваются и открывают вашему взору покрытое непроглядной тьмой помещение. Вы достаете телефон и включаете фонарик в надежде хоть что-то увидеть, но сумрак как будто поглощает весь свет, направленный в комнату.",
      "Внезапно из глубины помещения раздается голос.",
      "> (???) Н3ПРАВNЛЬНЫЙ ЭТАЖ!",
      "Вы слышите быстрый топот ног. Нечто приближается к вам. Вы лихорадочно нажимаете на кнопки панели управления, но лифт отказывается вам подчиняться.",
      "Грохот шагов становится все громче. Чувствуя неминуемую опасность, вы вжимаетесь в стену лифта и закрываете глаза...",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_0",
      },
    ],
    imageName: "q_59",
    audioTrackName: "office-cursed-theme",
    isGlitch: true,
  },

  q_60: {
    type: "with-number-input",
    description: [
      "Лифт устремляется вверх, вжимая вас в пол.",
      "Поездка проходит быстро. С грохотом лифт резко тормозит. Короткий сигнал оповещает о прибытии.",
      "Двери открываются. Перед вами все тот же холл бизнес-центра, из которого вы только что вошли в лифт.",
      "> (вы) Что за хуйня?",
      "Пока вы пытаетесь понять, как так получилось, двери снова закрываются. Лифт стоит на месте — нужно выбрать этаж.",
    ],
    nextIdsByAnswer: [
      {
        answer: "47",
        next_id: "q_61",
      },
    ],
    defaultNextId: "q_59",
    imageName: "q_57",
    audioTrackName: "office-cursed-theme",
    isGlitch: true,
  },

  q_61: {
    type: "basic",
    description: [
      "Лифт устремляется вверх, вжимая вас в пол.",
      "Поездка проходит быстро. С грохотом лифт резко тормозит. Короткий сигнал оповещает о прибытии.",
      "Вы выходите из лифта, и перед вами предстает высокий и просторный зал. Минималистичный, стильно оформленный интерьер выдает место обитания высокопоставленного руководства.",
      "Вы рассматриваете центр зала, где на невысоком подиуме расположился широкий стол из древесного массива.",
      "За столом сидят три человека. Их широкие улыбки можно разглядеть даже издалека. Там же, напротив, свободен роскошный стул — вас явно приглашают сесть.",
    ],
    options: [
      {
        label: "Присесть.",
        nextId: "q_62",
      },
    ],
    imageName: "q_61",
    audioTrackName: "boss-theme",
  },

  q_62: {
    type: "basic",
    description: [
      "Вы поднимаетесь по невысоким ступеням подиума и усаживаетесь на стул.",
      "> (босс № 1) Добрый день, Ва*****н.",
      "> (босс № 2) Мы ожидали Вас.",
      "> (босс № 3) Вы смогли найти нас...",
      "Вы сразу обращаете внимание на весьма странную манеру речи ваших собеседников. Они резко перехватывают реплики друг друга — так, будто говорят по заранее заготовленному сценарию.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_63",
      },
    ],
    imageName: "q_62",
    audioTrackName: "boss-theme",
  },

  q_63: {
    type: "basic",
    description: [
      "> (босс № 1) Наверное, это было...",
      "> (босс № 2) Непросто.",
      "> (босс № 3) Да, непросто...",
      "> (босс № 1) Можно сказать, что...",
      "> (босс № 3) Первый этап ПР0ЙД3Н!",
      "Вся троица резко заливается пугающим смехом.",
      "> (вы) Это собеседование?",
      "> (босс № 1) Нет...",
      "> (босс № 2) Вы уже приняты...",
      "> (вы) Но я...",
      "> (босс № 3) Вам предстоит пройти NСПЬ|ТАТ3ЛЬНЬ|Й СР0К.",
      "Люди напротив вас снова засмеялись.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_64",
      },
    ],
    imageName: "q_62",
    audioTrackName: "boss-theme",
  },

  q_64: {
    type: "basic",
    description: [
      "> (босс № 1) Сейчас Вы получите...",
      "> (босс № 2) Ваше первое задание.",
      "> (босс № 3) Да, ЗА-ДА-НИ-Е!",
      "Помещение вновь заполняется пугающим смехом.",
      "> (босс № 2) Распечатайте нам договор.",
      "> (босс № 1) Договор?",
      "> (босс № 3) Д0Г0В0Р.",
      "> (босс № 2) На поставку воды для кулера.",
      "> (босс № 3) ПРИСТУПАЙТЕ!",
      "Тут же стул опрокидывается, и вы стремительно летите назад и врезаетесь в пол.",
      "От удара у вас белеет в глазах...",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_65",
      },
    ],
    imageName: "q_62",
    audioTrackName: "boss-theme",
  },

  q_65: {
    type: "basic",
    description: [
      "Вы приходите в себя в офисном кресле. Со всех сторон на вас сыпятся суетливые звуки характерные рабочему процессу. Вы отрываете потяжелевшую голову от стола и видите перед собой типичное рабочее место. Все по 5S.",
      "Вы осматриваетесь по сторонам. Куча уставших клерков снуют по помещению, занятые своими делами: кто-то нависает над принтером, кто-то стучит по клавиатуре, кто-то вполголоса разговаривает по телефону.",
      "> (вы) Точно, задание. Мне нужен договор.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_66",
      },
    ],
    imageName: "q_65",
    audioTrackName: "boss-theme",
  },

  q_66: {
    type: "basic",
    description: [
      "Вы выводите компьютер из спящего режима и изучаете содержание рабочего стола.",
      "Среди множества однотипных файлов вы замечаете название «Поставка АКВА ФРЕШ+ № 23_47_2023.doc», однако ваше внимание привлекает другой файл — «Характеристика_Ва*****н_К.doc».",
      "> (вы) Характеристика на меня? Что за...",
      "Вы пытаетесь открыть документ, но ваши попытки ни к чему не приводят. Пытаясь сделать с файлом хоть что-то, вы кликаете на него правой кнопкой мыши. В контекстном меню вы видите пункт «распечатать».",
    ],
    options: [
      {
        label: "Распечатать договор",
        nextId: "q_67",
      },
      {
        label: "Распечатать характеристику",
        nextId: "q_76",
      },
    ],
    imageName: "q_66",
    audioTrackName: "boss-theme",
  },

  q_67: {
    type: "basic",
    description: [
      "> (вы) Не хотелось бы получить пиздюлей в первый же день...",
      "Вы отправляете договор в печать и слышите, как начинает шуметь принтер. Он работает все громче, и уже через пару секунд звук становится невыносимым. Вы больше не можете удерживать равновесие и падаете на пол. Из последних сил вы пытаетесь доползти до принтера, но сознание быстро покидает вас.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_68",
      },
    ],
    imageName: "q_67",
    audioTrackName: "boss-theme",
  },

  q_68: {
    type: "basic",
    description: [
      "Открыв глаза, вы вновь обнаруживаете себя за столом со странной троицей.",
      "> (босс № 2) Превосходно!",
      "> (босс № 1) Замечательно!",
      "> (босс № 3) Благодаря Вам наша компания не умрет от жажды.",
      "Комната заполняется смехом.",
      "> (босс № 2) Как жаль, что мы не верблюды...",
      "> (босс № 1) Кхм... да...",
      "> (босс № 3) СЛЕДУЮЩЕЕ ЗАДАНИЕ!",
      "> (босс № 1) Свяжитесь...",
      "> (босс № 2) С букинг-агентством.",
      "> (босс № 3) Нам нужен К0РП0РАТNВ!",
      "Пытаясь хоть как-то встрять в разговор, вы быстро вскакиваете из-за стола. В тот же момент вы обнаруживаете себя возле прежнего рабочего места.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_69",
      },
    ],
    imageName: "q_62",
    audioTrackName: "boss-theme",
  },

  q_69: {
    type: "basic",
    description: [
      "> (вы) Так... на этот раз нужно сделать звонок.",
      "Вы усаживаетесь за стол, взгляд падает на бесхозный ежедневник. Вы раскрываете его по закладке и видите список номеров. На одной из строк отмечен следующий номер:",
      "89275***23* — Event Attack",
      "Судя по всему, это контакт букинг-агентства.",
      "Вы достаете телефон и начинаете вводить номер. После первых цифр на экране всплывает подсказка:",
      "89275***12* — Ро**н Па***кин.",
      "> (вы) Хм-м-м... Он тоже справится...",
    ],
    options: [
      {
        label: "Позвонить в Event Attack",
        nextId: "q_70",
      },
      {
        label: "Позвонить Ро*е Па***кину",
        nextId: "q_74",
      },
    ],
    imageName: "q_65",
    audioTrackName: "boss-theme",
  },

  q_70: {
    type: "basic",
    description: [
      "Немного поразмыслив, вы все-таки решаете обратиться в Event Attack и допечатываете номер.",
      "Поднеся телефон к уху, вы слышите длинные гудки, которые тут же начинают ускоряться. Спустя несколько мгновений они перерастают в сплошной шум. Вы отбрасываете телефон в сторону, но статичный сигнал не исчезает, ваша голова начинает раскалываться. Пытаясь спастись, вы плотно закрываете уши ладонями и сильно зажмуриваетесь.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_71",
      },
    ],
    imageName: "q_66",
    audioTrackName: "boss-theme",
  },

  q_71: {
    type: "basic",
    description: [
      "Звук гудков резко обрывается, и вы открываете глаза.",
      "> (босс № 3) Отлично!",
      "> (босс № 2) Очень хорошо!",
      "> (босс № 1) Вы приняты!",
      "Вы немного сжались, приготовившись к раздражающему смеху этой троицы. Однако в этот раз никто не смеется.",
      "> (босс № 2) Можно сказать что...",
      "> (босс № 1) Теперь мы можем перейти...",
      "> (босс № 3) К действительно важной 3АДАЧЕ.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_72",
      },
    ],
    imageName: "q_62",
    audioTrackName: "boss-theme",
  },

  q_72: {
    type: "basic",
    description: [
      "С этими словами мужчина по центру аккуратно двигает запечатанный конверт в вашу сторону.",
      "> (босс № 2) Вы найдете все...",
      "> (босс № 1) Инструкции внутри.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_73",
      },
    ],
    imageName: "q_72",
    audioTrackName: "boss-theme",
  },

  q_73: {
    type: "basic",
    description: [
      "Вы робко протягиваете руки к конверту и раскрываете его. Внутри лежат две фотографии, подписанные как «место» и «цель».",
      "На фотокарточке с подписью «место» — знакомая кофейня, которую вы часто посещаете по дороге домой. Перевернув карточку, вы видите адрес, который подтверждает ваши догадки. Вы кладете ее на стол.",
      "После этого вы всматриваетесь в фотографию с подписью «цель». Вам становится дурно, когда вы узнаете на изображении себя. Вы хватаетесь за голову и осыпаетесь на стуле.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "r_4",
      },
    ],
    imageName: "q_73",
    audioTrackName: "boss-theme",
  },

  q_74: {
    type: "basic",
    description: [
      "Немного поразмыслив, вы все же решаете позвонить Ро*е Па***кину и вводите оставшиеся цифры. После нескольких длинных гудков абонент поднимает трубку.",
      "В ухо тут же бьет громкая палитра эмо-звуков.",
      "> (Ро**н Па***кин) О-о-о, здарова!",
      "Из-за музыки на фоне вы едва различаете его слова. Кажется, это сет группы V*va il fi**s!.",
      "> (вы) Привет. Слушай, тут такое дело...",
      "Вы не успеваете закончить фразу. Звонок прерывается, из динамика разносится оглушающий шум.",
      "Вы отбрасываете телефон в сторону, но шум не исчезает, ваша голова начинает раскалываться. Пытаясь спастись, вы плотно закрываете уши ладонями и сильно зажмуриваетесь.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_75",
      },
    ],
    imageName: "q_74_79",
    audioTrackName: "boss-theme",
  },

  q_75: {
    type: "basic",
    description: [
      "Шум резко обрывается, и вы открываете глаза.",
      "> (босс № 1) В этот раз...",
      "> (босс № 3) ВЫ НЕ СПРАВИЛИСЬ!",
      "Повисла небольшая пауза.",
      "> (босс № 1) Не переживайте.",
      "> (босс № 2) Тем не менее...",
      "> (босс № 3) Вы прошли ИСПЫТАТЕЛЬНЫЙ СРОК!",
      "> (босс № 1) Поздравляю. Вы приняты!",
      "Вы немного сжались, приготовившись к раздражающему смеху этой троицы, однако в этот раз никто не смеется.",
      "> (босс № 2) Можно сказать, что...",
      "> (босс № 1) Теперь мы можем перейти...",
      "> (босс № 3) К действительно важной 3АДАЧЕ.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_72",
      },
    ],
    imageName: "q_62",
    audioTrackName: "boss-theme",
  },

  q_76: {
    type: "basic",
    description: [
      "Вы отправляете файл в печать и слышите, как шумит принтер. Он работает все громче, и уже через пару секунд звук становится невыносимым. Вы больше не можете удерживать равновесие и падаете на пол. Из последних сил вы пытаетесь доползти до принтера, но сознание быстро покидает вас.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_77",
      },
    ],
    imageName: "q_76",
    audioTrackName: "boss-theme",
  },

  q_77: {
    type: "basic",
    description: [
      "Открыв глаза, вы вновь обнаруживаете себя за столом со странной троицей.",
      "> (босс № 2) К сожалению...",
      "> (босс № 3) ВЫ НЕ СПРАВИЛИСЬ!",
      "> (босс № 1) Такое простое задание...",
      "\n",
      "> (босс № 2) Не переживайте.",
      "> (босс № 1) У Вас еще будет возможность...",
      "> (босс № 2) Проявить себя.",
      "> (босс № 3) СЛЕДУЮЩЕЕ ЗАДАНИЕ!",
      "> (босс № 1) Свяжитесь...",
      "> (босс № 2) С букинг-агентством.",
      "> (босс № 3) Нам нужен К0РП0РАТNВ!",
      "Пытаясь хоть как-то встрять в разговор, вы быстро вскакиваете из-за стола. В тот же момент вы обнаруживаете себя возле прежнего рабочего места.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_78",
      },
    ],
    imageName: "q_62",
    audioTrackName: "boss-theme",
  },

  q_78: {
    type: "basic",
    description: [
      "> (вы) Так... на этот раз нужно сделать звонок.",
      "Вы усаживаетесь за стол, взгляд падает на бесхозный ежедневник. Вы раскрываете его по закладке и видите список номеров. На одной из строк отмечен следующий номер:",
      "89275***23* — Event Attack",
      "Судя по всему, это контакт букинг-агентства.",
      "Вы достаете телефон и начинаете вводить номер. После первых цифр на экране всплывает подсказка:",
      "89275***12* — Ро**н Па***кин.",
      "> (вы) Хм-м-м... Он тоже справится...",
    ],
    options: [
      {
        label: "Позвонить в Event Attack",
        nextId: "q_70",
      },
      {
        label: "Позвонить Ро*е Па***кину",
        nextId: "q_79",
      },
    ],
    imageName: "q_65",
    audioTrackName: "boss-theme",
  },

  q_79: {
    type: "basic",
    description: [
      "Немного поразмыслив, вы все же решаете позвонить Ро*е Па***кину и вводите оставшиеся цифры. После нескольких длинных гудков абонент поднимает трубку.",
      "В ухо тут же бьет громкая палитра эмо-звуков.",
      "> (Ро**н Па***кин) О-о-о, здарова!",
      "Из-за музыки на фоне вы едва различаете его слова. Кажется, это сет группы V*va il fi**s!.",
      "> (вы) Привет. Слушай, тут такое дело...",
      "Вы не успеваете закончить фразу. Звонок прерывается, из динамика разносится оглушающий шум.",
      "Вы отбрасываете телефон в сторону, но шум не исчезает, ваша голова начинает раскалываться. Пытаясь спастись, вы плотно закрываете уши ладонями и сильно зажмуриваетесь.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_80",
      },
    ],
    imageName: "q_74_79",
    audioTrackName: "boss-theme",
  },

  q_80: {
    type: "basic",
    description: [
      "Шум резко обрывается, и вы открываете глаза.",
      "> (босс № 3) КАКАЯ ПОСРЕДСТВЕННОСТЬ!",
      "> (босс № 1) Возмутительно!",
      "> (босс № 2) Неужели Вы и сейчас...",
      "> (босс № 1) Не поняли, что от Вас требуется?",
      "> (босс № 2) Вы намеренно игнорируете...",
      "> (босс № 3) Наши УКА3АНNR?",
      "\n",
      "Повисла гнетущая пауза.",
      "\n",
      "> (вы) Понимаете, я...",
      "> (босс № 1) Что ж...",
      "> (босс № 2) Вы не прошли испытательный срок!",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_81",
      },
    ],
    imageName: "q_62",
    audioTrackName: "boss-theme",
  },

  q_81: {
    type: "basic",
    description: [
      "С этими словами все трое синхронно встают, разворачиваются и уходят.",
      "> (вы) Извините, но ведь я...",
      "Один из них останавливается и обращается к вам.",
      "> (босс № 3) Мы закончили. Д0 СВNДАНИR.",
      "Последняя фраза прозвучала резко. Вы напрягаетесь, приготовившись к очередному сюрпризу, но ничего не происходит. Мужчины удаляются и скрываются за дверями кабинета.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_82",
      },
    ],
    imageName: "q_62",
    audioTrackName: "boss-theme",
  },

  q_82: {
    type: "basic",
    description: [
      "Тут же вы слышите сигнал прибытия лифта. Встав со стула, вы оборачиваетесь и видите, как к вам направляется знакомый силуэт. Это мужчина с ресепшена.",
      "Он подходит к вам вплотную.",
      "> (вы) Эм-м-м...",
      "> (мужчина) Вам нужно уйти. Пройдемте.",
      "Мужчина жестом приглашает вас проследовать за ним. Вы молча киваете.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "q_83",
      },
    ],
    imageName: "q_55",
    audioTrackName: "boss-theme",
  },

  q_83: {
    type: "basic",
    description: [
      "В полной тишине вы спускаетесь на первый этаж. Двери лифта распахиваются. Перед вами предстает привычный вид улицы.",
      "> (мужчина) До свидания.",
      "> (вы) Как это...",
      "> (мужчина) ДО СВИДАНИЯ.",
      "Вы делаете шаг из кабины и слышите, как двери лифта захлопываются. Обернувшись, вы видите перед собой глухую стену.",
    ],
    options: [
      {
        label: "<далее>",
        nextId: "r_5",
      },
    ],
    imageName: "q_83",
    audioTrackName: "boss-theme",
  },
};
export const resultsData: Results = {
  r_1: {
    description: [
      "Вы обнаруживаете себя сидящим за столом в тусклом помещении. Напротив сидят два человека и о чем-то разговаривают между собой. Тот, что слева, произносит что-то нечленораздельное и кидает вам папку и ручку. Недолго думая, вы начинаете последовательно расписываться на каждом листе.",
      "Вы не успеваете детально изучить содержание документов, однако замечаете, что почти на каждой странице фигурирует фраза «предумышленное убийство».",
      "Подписав каждый лист, вы возвращаете папку. С довольным выражением лица мужчина и его напарник встают со своих мест. Вам заламывают руки и уводят вглубь здания.",
      "Кaжется, ВЬ| где-то ОШИ6ЛNСЬ...",
    ],
    imageName: "r_1",
    isEnding: false,
    endingScreenDescription: "",
  },
  r_2: {
    description: [
      "Собравшись с мыслями, вы садитесь обратно в автомобиль и продолжаете свой путь через мост, как можно дальше от города...",
    ],
    imageName: "r_2",
    isEnding: true /* kk.mp3 */,
    audioTrackName: "kk",
    endingScreenDescription:
      "Вы открыли <b>хорошую концовку.</b><br/> Трек «Аферист — Никому нет дела до тебя»<br>с альбома «Это начнется заново // ТВОЙРУТ» разблокирован.",
  },
  r_3: {
    description: [
      "Вы пытаетесь встать, но тело вас не слушается. В глазах темнеет, и вы начинаете терять сознание. Последнее, что вы слышите, — звук катящейся по ступенькам электронной сигареты.",
    ],
    imageName: "r_3",
    isEnding: false,
    endingScreenDescription: "",
  },
  r_4: {
    description: [
      "> (босс № 3) АХ ДА!",
      "> (босс № 2) Точно.",
      "> (босс № 1) Не забудьте взять с собой это...",
      "Мужчина справа сует руку под пиджак и достает из внутреннего кармана нож.",
      "Указания вам понятны.",
      "> (вы) Хорошо! Сделаю все в ЛУЧШ3М ВNДЕ.",
      "С этими словами вы хватаете нож и заливаетесь смехом.",
    ],
    imageName: "r_4",
    isEnding: true /* npc.mp3 */,
    audioTrackName: "npc",
    endingScreenDescription:
      "Вы открыли <b>плохую концовку.</b><br/> Трек «Аферист — NPC»<br>с альбома «Это начнется заново // ТВОЙРУТ» разблокирован.",
  },
  r_5: {
    description: [
      "Вы долго стоите на месте и пытаетесь осмыслить произошедшее. С самого утра вы сталкиваетесь с чем-то поистине странным и необъяснимым.",
      "> (вы) СУКА БЛЯДЬ!",
      "Вы бьете кулаком в стену, из которой недавно вышли.",
      "> (вы) КАК ВЫ ВСЕ ЗАЕБАЛИ! ИДИТЕ НА ХУЙ.",
      "С каждой секундой вы становитесь все злее. В голове проносятся образы и воспоминания, связанные с вашей работой, и гнев нарастает. Вы осознаете, что больше не сможете вернуться к прежней жизни.",
      "В ярости вы достаете телефон и пишете менеджеру. После серии оскорблений вы заявляете об увольнении.",
      "Вы немного успокаиваетесь и начинаете думать, что делать дальше.",
      "> (вы) Только не домой...",
    ],
    imageName: "r_5",
    isEnding: true /* npc.mp3 */,
    audioTrackName: "npc",
    endingScreenDescription:
      "Вы открыли <b>истинную концовку.</b><br/> Трек «Аферист — NPC»<br>с альбома «Это начнется заново // ТВОЙРУТ» разблокирован.",
  },
};
