import { useContext, useEffect, useState } from "react";
import { SoundtrackContext } from "../../../contexts/soundtrack-context";
import s from "./pre-titles-screen.module.css";
import { Button } from "../../button/button";
import { FadeInComponent } from "../fade-in-component/fade-in-component";

type Props = {
  onShowTitles: () => void;
};

export const PreTitlesScreen = ({ onShowTitles }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { stop } = useContext(SoundtrackContext);

  useEffect(() => {
    stop();
  }, [stop]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <FadeInComponent isVisible={isVisible} transitionDuration={2000}>
      <div className={s.root}>
        <div className={s.content}>
          <Button onClick={onShowTitles}>Что дальше?</Button>
        </div>
      </div>
    </FadeInComponent>
  );
};
