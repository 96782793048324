import s from "./fade-in-component.module.css";

type Props = {
  isVisible: boolean;
  children?: React.ReactNode;
  transitionDuration?: number;
};
export const FadeInComponent = ({
  isVisible,
  children,
  transitionDuration = 300,
}: Props) => {
  return (
    <div
      className={s.root}
      data-visible={isVisible}
      style={{ transitionDuration: `${transitionDuration}ms` }}
    >
      {children}
    </div>
  );
};
