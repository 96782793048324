import { useState } from "react";

import { Button } from "../button/button";
import { MusicOptionsScreen } from "../music-options-screen/music-options-screen";
import { LogoContainer } from "../logo-container/logo-container";

import uiText from "../../data/ui-text.json";
import { VkLogo, YtLogo } from "../icons/icons";
import s from "./main-menu-screen.module.css";

type Props = {
  onStart: () => void;
};

export const MainMenuScreen = ({ onStart }: Props) => {
  const [isShowMusicOptions, setIsShowMusicOptions] = useState(false);

  const showMusicOptions = () => {
    setIsShowMusicOptions(true);
  };

  return (
    <div className={s.content}>
      <LogoContainer gameScreen="main-menu-screen" />
      {isShowMusicOptions ? (
        <MusicOptionsScreen onStart={onStart} />
      ) : (
        <>
          <p className={s.gameName}>{uiText.startScreen.gameName}</p>
          <div className={s.mainScreenImgContainer}>
            <img src={"images/main-screen-picture.webp"} alt="Заставка" />
          </div>
          <p className={s.gameSlogan}>{uiText.startScreen.gameSlogan}</p>
          <div className={s.buttonsContainer}>
            <Button onClick={showMusicOptions} size="small">
              {uiText.startScreen.gameStart}
            </Button>
          </div>
          <div className={s.socialsContainer}>
            <div className={s.icons}>
              <a
                href="https://vk.com/apheristlovefist"
                target="_blank"
                rel="noopener noreferrer"
              >
                <VkLogo />
              </a>
              <a
                href="https://www.youtube.com/@chaikarecords2974/featured"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YtLogo />
              </a>
            </div>
            <a
              href="https://chaikarecords.com/"
              target="_blank"
              rel="noopener noreferrer"
              data-type="chaika-records"
            >
              chaikarecords.com
            </a>
          </div>
        </>
      )}
    </div>
  );
};
