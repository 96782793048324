import { createContext, useRef, useState } from "react";
import mainTheme from "../assets/music/main-theme.mp3";
import officeTheme from "../assets/music/office-theme.mp3";
import bossTheme from "../assets/music/boss-theme.mp3";
import cursedTheme from "../assets/music/cursed-theme.mp3";
import officeCursedTheme from "../assets/music/office-cursed-theme.mp3";
import coffeeStreetfightTheme from "../assets/music/coffee-streetfight-theme.mp3";
import { AudioTrackNames } from "../types";

const AUDIO_TRACKS = {
  "main-theme": mainTheme,
  "office-theme": officeTheme,
  "boss-theme": bossTheme,
  "cursed-theme": cursedTheme,
  "office-cursed-theme": officeCursedTheme,
  "coffee-streetfight-theme": coffeeStreetfightTheme,
};

const INITIAL_TRACK = "main-theme";

type SoundtrackContextType = {
  isPlaying: boolean;
  currentTrack: AudioTrackNames;
  playPause: () => void;
  stop: () => void;
  stopWithFadeOut: () => void;
  switchMusic: (trackName: AudioTrackNames) => void;
};

export const SoundtrackContext = createContext<SoundtrackContextType>({
  isPlaying: false,
  currentTrack: INITIAL_TRACK,
  playPause: () => {},
  stop: () => {},
  stopWithFadeOut: () => {},
  switchMusic: (name) => {},
});

type SoundtrackProviderProps = {
  children: JSX.Element;
};

export const SoundtrackProvider = ({ children }: SoundtrackProviderProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] =
    useState<AudioTrackNames>(INITIAL_TRACK);

  const audioPlayerRef = useRef(new Audio());

  const stop = () => {
    if (!audioPlayerRef.current) {
      return;
    }

    audioPlayerRef.current.pause();
    audioPlayerRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const stopWithFadeOut = () => {
    if (!audioPlayerRef.current) {
      return;
    }

    if (!audioPlayerRef.current.src) {
      return;
    }

    /* TODO: Разобраться, почему это работает)) */
    const fadeAudio = setInterval(() => {
      if (audioPlayerRef.current.volume !== 0) {
        audioPlayerRef.current.volume -= 0.1;
      }

      if (audioPlayerRef.current.volume < 0.003) {
        audioPlayerRef.current.volume = 0;
        clearInterval(fadeAudio);
        stop();
        audioPlayerRef.current.volume = 1; // Сброс громкости на дефолтное значение
      }
    }, 200);
  };

  const playPause = () => {
    if (!audioPlayerRef.current) {
      return;
    }

    if (!audioPlayerRef.current.src) {
      audioPlayerRef.current.src = AUDIO_TRACKS[INITIAL_TRACK];
      audioPlayerRef.current.loop = true;
    }

    if (audioPlayerRef.current.paused) {
      audioPlayerRef.current.play();
      setIsPlaying(true);
    } else {
      audioPlayerRef.current.pause();
      setIsPlaying(false);
    }
  };

  const switchMusic = (audioTrackName: AudioTrackNames) => {
    if (!audioPlayerRef.current) {
      return;
    }

    /* TODO: Возможно стоит порефакторить условия */
    if (audioPlayerRef.current.duration > 0 && !audioPlayerRef.current.paused) {
      audioPlayerRef.current.src = AUDIO_TRACKS[audioTrackName];
      audioPlayerRef.current.play();
      setCurrentTrack(audioTrackName);
    }
  };

  return (
    <SoundtrackContext.Provider
      value={{
        isPlaying,
        playPause,
        currentTrack,
        stop,
        switchMusic,
        stopWithFadeOut,
      }}
    >
      {children}
    </SoundtrackContext.Provider>
  );
};
