import { useContext } from "react";
import { Button } from "../button/button";
import { SoundtrackContext } from "../../contexts/soundtrack-context";

import uiText from "../../data/ui-text.json";
import s from "./music-options-screen.module.css";

type Props = {
  onStart: () => void;
};

export const MusicOptionsScreen = ({ onStart }: Props) => {
  const { isPlaying, playPause } = useContext(SoundtrackContext);

  return (
    <div className={s.content}>
      <div className={s.buttonsContainer}>
        <Button
          onClick={() => {
            if (!isPlaying) {
              playPause();
            }
            onStart();
          }}
          size="big"
          textAlign="center"
        >
          {uiText.startScreen.turnMusicOn}
        </Button>
        <Button
          onClick={() => {
            if (isPlaying) {
              playPause();
            }
            onStart();
          }}
          size="big"
          textAlign="center"
        >
          {uiText.startScreen.continueWithoutMusic}
        </Button>
      </div>
    </div>
  );
};
