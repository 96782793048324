import { Ending } from "../ending/ending";
import { Question } from "../question/question";

import { questionsData } from "../../data/questions";
import { resultsData } from "../../data/questions";

import { isResultId } from "../../helpers/is-result-id";
import { CardHeader } from "../card-header/card-header";

type Props = {
  currentQuestionId: string;
  onRestart: () => void;
  onNext: (id: string) => void;
  onFinishGame: () => void;
};

export const GameScreen = ({
  currentQuestionId,
  onRestart,
  onNext,
  onFinishGame,
}: Props) => {
  const isResult = isResultId(currentQuestionId);

  return (
    <>
      <CardHeader onRestart={onRestart} />
      {isResult ? (
        <Ending
          result={resultsData[currentQuestionId]}
          onRestart={onRestart}
          onFinishGame={onFinishGame}
        />
      ) : (
        <Question
          question={questionsData[currentQuestionId]}
          onNext={onNext}
          onRestart={onRestart}
        />
      )}
    </>
  );
};
