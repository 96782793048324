import React from "react";
import s from "./glitch.module.css";

type Props = {
  srcPath: string;
};

export const Glitch = ({ srcPath }: Props) => {
  return (
    <div className={s.glitch}>
      <img src={srcPath} alt="" />
      <div className={s.glitchLayers}>
        <img src={srcPath} alt="" className={s.glitchLayer} />
        <img src={srcPath} alt="" className={s.glitchLayer} />
        <img src={srcPath} alt="" className={s.glitchLayer} />
      </div>
    </div>
  );
};
