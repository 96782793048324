type Options = {
  data: {
    defaultNextId: string;
    nextIdsByAnswer: { answer: string; next_id: string }[];
  };
  userInput: string;
};

export const getNextIdForNumberInputQuestion = ({
  data,
  userInput,
}: Options) => {
  const { defaultNextId, nextIdsByAnswer } = data;
  const foundItem = nextIdsByAnswer.find((el) => el.answer === userInput);
  return foundItem ? foundItem.next_id : defaultNextId;
};
