import { useEffect, useState } from "react";
import { GameContainer } from "../game-container/game-container";
import { MainMenuScreen } from "../main-menu-screen/main-menu-screen";
import { GameScreen } from "../game-screen/game-screen";
import { TitlesScreen } from "../titles-screen/titles-screen";
import { Loader } from "../loader/loader";

import { preloadImages } from "../../helpers/preload-images";

import { useLocalStorage } from "../../hooks/use-local-storage";

import { resultsData } from "../../data/questions";
import { IMAGES_PATHS } from "../../data/constants";

const FIRST_CARD_ID = "q_0";

export const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isGameFinished, setIsGameFinished] = useState(false);

  /* Наш стейт ответов юзера мы синхронизированно обновляем внутри localStorage, чтобы не потерять прогресс в результате случайной перезагрузки */
  const [answersHistory, setAnswersHistory] = useLocalStorage<string[]>(
    "history_ids",
    []
  );

  const onNext = (id: string) => setAnswersHistory([...answersHistory, id]);
  const onStart = () => {
    setAnswersHistory([FIRST_CARD_ID]);
  };
  const onRestart = () => {
    if (window.confirm("Точно хотите начать сначала?")) {
      setAnswersHistory([]);
      setIsGameFinished(false);
    }
  };
  const onFinishGame = () => {
    setIsGameFinished(true);
  };

  useEffect(() => {
    preloadImages(IMAGES_PATHS).then(() => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Loader />;

  const isShowStartMenu = answersHistory.length === 0;

  if (isShowStartMenu)
    return (
      <GameContainer>
        <MainMenuScreen onStart={onStart} />
      </GameContainer>
    );

  const currentQuestionId = answersHistory[answersHistory.length - 1];

  return (
    <GameContainer>
      {isGameFinished ? (
        <TitlesScreen
          result={resultsData[currentQuestionId]}
          onRestart={onRestart}
        />
      ) : (
        <GameScreen
          currentQuestionId={currentQuestionId}
          onRestart={onRestart}
          onNext={onNext}
          onFinishGame={onFinishGame}
        />
      )}
    </GameContainer>
  );
};
