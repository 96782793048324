import { ApheristLogo } from "../icons/icons";
import { GameScreens } from "../../types";
import s from "./logo-container.module.css";

type Props = {
  gameScreen: GameScreens;
};

export const LogoContainer = ({ gameScreen = "main-menu-screen" }: Props) => {
  return (
    <div className={s.container} data-screen={gameScreen}>
      <ApheristLogo />
    </div>
  );
};
