export const preloadImages = async (srcArray: string[]) => {
  const promises = await srcArray.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.src = src;
      img.onload = () => resolve(true);
      img.onerror = () => reject(false);
    });
  });

  return await Promise.all(promises);
};

export const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.src = src;
    img.onload = () => resolve(true);
    img.onerror = () => reject(false);
  });
};
