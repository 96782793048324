import { useEffect, useState } from "react";
import { Button } from "../button/button";

import uiText from "../../data/ui-text.json";

import { Result as ResultT } from "../../types";

import s from "./ending.module.css";

type Props = {
  result: ResultT;
  onRestart: () => void;
  onFinishGame: () => void;
};

export const Ending = ({ result, onRestart, onFinishGame }: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleResultButtonClick = result.isEnding ? onFinishGame : onRestart;

  const buttonFinalLabel = result.isEnding
    ? uiText.resultScreen.endGame
    : uiText.resultScreen.startAgain;

  return (
    <div className={s.root} data-visible={isVisible}>
      <div className={s.content}>
        <div className={s.imgContainer}>
          <img src={`/images/${result.imageName}.webp`} alt="" />
        </div>
        <div className={s.textContent}>
          <div className={s.description}>
            {result.description.map((el, idx) => (
              <p key={idx}>{el}</p>
            ))}
          </div>
          <div className={s.answers}>
            <Button size="big" onClick={handleResultButtonClick}>
              {buttonFinalLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
