import { useContext } from "react";
import { Restart, VolumeOff, VolumeOn } from "../icons/icons";
import { SoundtrackContext } from "../../contexts/soundtrack-context";
import s from "./card-header.module.css";
import { LogoContainer } from "../logo-container/logo-container";

type Props = {
  onRestart: () => void;
};

export const CardHeader = ({ onRestart }: Props) => {
  const { isPlaying, playPause } = useContext(SoundtrackContext);

  return (
    <div className={s.header}>
      <LogoContainer gameScreen="game-screen" />
      <div className={s.buttonsContainer}>
        <button onClick={playPause}>
          {isPlaying ? <VolumeOff /> : <VolumeOn />}
        </button>
        <button onClick={onRestart}>
          <Restart />
        </button>
      </div>
    </div>
  );
};
