import { LogoContainer } from "../logo-container/logo-container";
import s from "./loader.module.css";

export const Loader = () => (
  <div className={s.container}>
    <div className={s.content} data-animated="true">
      <LogoContainer gameScreen="loading-screen" />
    </div>
  </div>
);
